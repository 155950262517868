// 菜单等级
export const menuLevelObj = {
  1: "一级",
  2: "二级",
  3: "按钮",
};
export const menuLevelColor = {
  1: "#FF0000",
  2: "#E6A23C",
  3: "#1E9FFF",
};
export const cardOrderStatusColor = {
  0: "#E6A23C",
  1: "#009688",
  2: "#FF0000",
  3: "#909399",
  4: "#909399",
};
export const orderStatusColor = {
  1: "#FF0000",
  2: "#E6A23C",
  3: "#009688",
  4: "#909399",
  9: "#909399",
};
export const getTypeObj = {
  34: "注册奖励",
  24: "拉新奖励",
  44: "平台手动发放",
  54: "会员权益领取",
  14: "入群奖励",
  10: "积分兑换",
};

// 性别
export const sexObj = {
  0: "男",
  1: "女",
};
// 管理员
export const isAdminObj = {
  0: "普通用户",
  1: "管理员",
};
export const isAdminColor = {
  0: "#909399",
  1: "#1E9FFF",
};
// 商户状态
export const merchantStatusObj = {
  0: "停用",
  1: "启用",
};
// 卡券订单状态
export const cardOrderStatusObj = {
  0: "未使用",
  1: "使用中",
  3: "已使用",
  4: "已过期",
};
// 卡券状态
export const cardStatusObj = {
  0: "已下架",
  1: "售卖中",
};
// 卡券背景
export const cardBgArr = [
  {
    value: "0",
    url: require("../assets/images/card/0.jpg"),
  },
  {
    value: "1",
    url: require("../assets/images/card/1.png"),
  },
  {
    value: "2",
    url: require("../assets/images/card/2.png"),
  },
  {
    value: "3",
    url: require("../assets/images/card/3.png"),
  },
  {
    value: "4",
    url: require("../assets/images/card/4.png"),
  },
  {
    value: "5",
    url: require("../assets/images/card/5.png"),
  },
  {
    value: "6",
    url: require("../assets/images/card/6.png"),
  },
  {
    value: "7",
    url: require("../assets/images/card/7.png"),
  },
];
// 公告状态
export const bulletinStatusObj = {
  0: "未发布",
  1: "已发布",
  2: "已下架",
};
// 卡券类型
export const cardTypeObj = {
  1: "次卡",
  // 2: "时长卡",
  3: "代金券",
  5: "自定义券",
};

export const merchantStatusColor = {
  0: "#FF0000",
  1: "#009688",
};
// 店铺状态
export const shopStatusObj = {
  0: "停用",
  1: "启用",
};
export const shopStatusColor = {
  0: "#FF0000",
  1: "#009688",
};
// 店铺状态
export const shopTypeObj = {
  1: "直营店",
  2: "加盟店",
  3: "联营店",
};
// 设备状态
export const deviceStatusObj = {
  0: "离线",
  1: "在线",
};
export const deviceStatusColor = {
  0: "#FF0000",
  1: "#009688",
};
// 是否录入
export const inputStatusObj = {
  0: "未录入",
  1: "已录入",
};
// 物理绑定状态
export const bindStatusObj = {
  0: "未绑定",
  1: "已绑定",
};

// 首页时段切换对象
export const timeFrameObj = {
  1: "全部",
  2: "今日",
  3: "昨日",
  4: "近七天",
  5: "本月",
  6: "上月",
};

// 首页销售时段切换对象
export const timeSellObj = {
  1: "全部",
  // 2: "今日",
  // 3: "昨日",
  4: "近七天",
  7: "近30天",
  5: "本月",
  6: "上月",
};

// 首页财务统计上面
export const homeFinanceTopObj = [
  {
    title: "微信净收入",
    name: "wxCount",
    refundName: "wxRefund",
    icon: "iconfont icon-icon_vx",
    count: "-",
    refund: "-",
    type: "4",
  },
  {
    title: "线下净收入",
    name: "cashCount",
    refundName: "cashRefund",
    icon: "iconfont icon-icon_sr",
    count: "-",
    refund: "-",
    type: "2",
  },
  {
    title: "团购净收入",
    name: "groupCount",
    refundName: "groupRefund",
    icon: "iconfont icon-icon_tg",
    count: "-",
    refund: "-",
    type: "1",
  },
  {
    title: "本金净收入",
    name: "balanceCount",
    refundName: "balanceRefund",
    icon: "iconfont icon-icon_bj",
    count: "-",
    refund: "-",
    type: "5",
  },
  {
    title: "赠金净收入",
    name: "giveCount",
    refundName: "giveRefund",
    icon: "iconfont icon-icon_zj",
    count: "-",
    refund: "-",
    type: "6",
  },
];

// 首页财务统计下面
export const homeFinanceBottomObj = [
  {
    title: "位置下单净收入",
    name: "roomCount",
    refundName: "roomRefund",
    icon: "iconfont icon-icon_dd",
    count: "-",
    refund: "-",
  },
  {
    title: "卡券净收入",
    name: "cardCount",
    refundName: "cardRefund",
    icon: "iconfont icon-icon_kq",
    count: "-",
    refund: "-",
  },
  {
    title: "商品净收入",
    name: "goodsCount",
    refundName: "goodsRefund",
    icon: "iconfont icon-icon_sp",
    count: "-",
    refund: "-",
  },
];

// 首页用户统计
export const homeUserObj = [
  {
    title: "今日新增",
    name: "today",
    count: "-",
  },
  {
    title: "近7日新增",
    name: "last7Days",
    count: "-",
  },
  {
    title: "近30日新增",
    name: "last30Days",
    count: "-",
  },
  {
    title: "今日活跃",
    name: "updateToday",
    count: "-",
  },
  {
    title: "近7日活跃",
    name: "updateLast7Days",
    count: "-",
  },
  {
    title: "近30日活跃",
    name: "updateLast30Days",
    count: "-",
  },
];

export const payChannelObj = {
  1: "团购",
  2: "线下",
  4: "微信",
  5: "本金",
  6: "赠金",
};

export const businessTypeObj = {
  1: "卡券订单",
  2: "售卖柜订单",
  3: "派送订单",
  9: "位置订单",
};

// 订单状态
export const orderTypeObj = {
  1: "卡券订单",
  3: "商品订单",
  9: "位置订单",
};

// 订单状态
export const settleObj = {
  0: "待结算",
  1: "已结算",
};
//审核状态
export const auditsStatusObj = {
  0: "待审核",
  1: "已通过",
  2: "已拒绝",
};
// 适用时段
export const cardDateArr = [
  {
    value: "1",
    label: "不限制",
  },
  {
    value: "2",
    label: "周一至周五",
  },
  {
    value: "3",
    label: "工作日",
  },
  {
    value: "4",
    label: "双休日",
  },
  {
    value: "5",
    label: "法定节假日",
  },
  {
    value: "6",
    label: "双休日 + 法定节假日",
  },
  {
    value: "7",
    label: "其他",
  },
];
export const cardWeekArr = [
  {
    value: "1",
    label: "星期一",
  },
  {
    value: "2",
    label: "星期二",
  },
  {
    value: "3",
    label: "星期三",
  },
  {
    value: "4",
    label: "星期四",
  },
  {
    value: "5",
    label: "星期五",
  },
  {
    value: "6",
    label: "星期六",
  },
  {
    value: "7",
    label: "星期日",
  },
];
export const cardTipsObj = {
  1: "*该卡券周一、二、三、四、五、六、日，",
  2: "*该卡券周一、二、三、四、五，",
  3: "*该卡券周一、二、三、四、五、补班（不包含法定节假日），",
  4: "*该卡券周六、日（不包含补班），",
  5: "*该卡券法定节假日，",
  6: "*该卡券周六、日、法定节假日（不包含补班），",
};
export const continuousMaxHourObj = [
  {
    value: 0,
    label: "不限制",
  },
  {
    value: 0.5,
    label: "0.5小时",
  },
  {
    value: 1,
    label: "1小时",
  },
  {
    value: 2,
    label: "2小时",
  },
  {
    value: 3,
    label: "3小时",
  },
  {
    value: 4,
    label: "4小时",
  },
  {
    value: 5,
    label: "5小时",
  },
  {
    value: 6,
    label: "6小时",
  },
  {
    value: 7,
    label: "7小时",
  },
  {
    value: 8,
    label: "8小时",
  },
  {
    value: 9,
    label: "9小时",
  },
  {
    value: 10,
    label: "10小时",
  },
  {
    value: 11,
    label: "11小时",
  },
  {
    value: 12,
    label: "12小时",
  },
  {
    value: 13,
    label: "13小时",
  },
  {
    value: 14,
    label: "14小时",
  },
  {
    value: 15,
    label: "15小时",
  },
  {
    value: 16,
    label: "16小时",
  },
  {
    value: 17,
    label: "17小时",
  },
  {
    value: 18,
    label: "18小时",
  },
  {
    value: 19,
    label: "19小时",
  },
  {
    value: 20,
    label: "20小时",
  },
  {
    value: 21,
    label: "21小时",
  },
  {
    value: 22,
    label: "22小时",
  },
  {
    value: 23,
    label: "23小时",
  },
  {
    value: 24,
    label: "24小时",
  },
];
// 日期筛选
export const pickerOptions = {
  shortcuts: [
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近三个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近一年",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit("pick", [start, end]);
      },
    },
  ],
};
// 会员等级

export const vipLevel = [
  {
    value: 0,
    label: "普通用户",
  },
  {
    value: 1,
    label: "白银会员",
  },
  {
    value: 2,
    label: "铂金会员",
  },
  {
    value: 3,
    label: "黄金会员",
  },
  {
    value: 4,
    label: "钻石会员",
  },
  {
    value: 5,
    label: "黑金会员",
  },
];
// 用户管理
export const userMenuObj = [
  {
    title: "基本信息",
    path: "/vip/userInfo/baseInfo",
  },
  {
    title: "预约订单",
    path: "/vip/userInfo/userOrderList",
  },
  {
    title: "平台卡券",
    path: "/vip/userInfo/userCardList",
  },
  {
    title: "商品订单",
    path: "/vip/userInfo/goodList",
  },
  {
    title: "余额账户明细",
    path: "/vip/userInfo/balanceInfo",
  },
  {
    title: "充值明细",
    path: "/vip/userInfo/charge",
  },
  {
    title: "门店赠金概况",
    path: "/vip/userInfo/stortGift",
  },
  {
    title: "会员等级概况",
    path: "/vip/userInfo/memberLevel",
  },
  {
    title: "成长值明细",
    path: "/vip/userInfo/growthValue",
  },
  {
    title: "积分明细",
    path: "/vip/userInfo/point",
  },
];
// 会员配置菜单
export const vipCongfiMenu = [
  {
    title: "会员等级",
    path: "/vip/config/level",
  },
  {
    title: "会员权益",
    path: "/vip/config/rights",
  },
];
// 积分兑换配置
export const exchangeCongfiMenu = [
  {
    title: "积分兑卡券",
    path: "/point/exchange/card",
  },
  {
    title: "积分兑商品",
    path: "/point/exchange/goods",
  },
];
// 积分兑换订单
export const exchangeOrderMenu = [
  {
    title: "兑换卡券订单",
    path: "/point/order/card",
  },
  {
    title: "兑换商品订单",
    path: "/point/order/goods",}
];
// 服务类型配置
export const serviceTypeMenu = [
  {
    title: "包厢类",
    path: "/setting/serviceConfig/room",
  },
  {
    title: "台球类",
    path: "/setting/serviceConfig/billiard",
  },
  {
    title: "餐桌类",
    path: "/setting/serviceConfig/board",
  },
  {
    title: "仓储类",
    path: "/setting/serviceConfig/store",
  },
  {
    title: "工位类",
    path: "/setting/serviceConfig/desk",
  },
];
// 订单状态
export const orderStatusObj = {
  1: "待支付",
  2: "未开始",
  3: "进行中",
  4: "已完成",
  9: "已取消",
};
// 订单状态
export const shopObj = {
  2: "售卖柜订单",
  3: "派送订单",
};
// 商品状态
export const goodsStatusObj = {
  0: "已下架",
  1: "售卖中",
  2: "已售罄",
};
// 平台积分兑换商品状态
export const exchangeGoodsStatusObj = {
  0: "已下架",
  1: "售卖中",
};
// 积分流水明细
export const pointRecordType = {
  2:"售货柜订单",
  3:"派送订单",
  9: "位置订单",
  5: "活动充值赠送",
  14: "兑换卡券订单",
  15: "兑换商品订单",
  16: "积分过期",
  17: "每日签到",
  18: "会员达标"
};
// 流水方向
export const IO = {
  0: "收入",
  1: "支出",
};
// ------------------平台充值活动--------------------
export const activityStatus = {
  0: "未启动",
  1: "已启动",
  2: "已下架",
}
export const activityDateStatus = {
  0: "未开始",
  1: "生效中",
  2: "已过期",
}
// ------------------平台活动通知--------------------
// 打开类型
export const openType = {
  0: "不动作",
  1: "卡券",
  2: "充值",
  3: "公众号",
}
export const popupStatusObj = {
  0: "停用",
  1: "启用",
}
// // 单价单位
export const priceTypeObj = {
  1: "元/小时",
  2: "元/天",
  3: "元/月"
};
// // 封顶定价类型
export const limitPriceType= {
  2: "按天",
  3: "按月",
};
// // 单价单位
export const timeUnit = {
  1: "分钟",
  2: "小时",
  3: "天",
};
// // 押金单位
export const depositUnit = {
  1: "月租金",
  2: "元"
}
