// 验证规则
export const rules = {
  functionName: [
    {
      required: true,
      message: "请输入菜单名称",
      trigger: "blur",
    },
  ],
  sortWeight: [
    {
      required: true,
      message: "请输入排列序号",
      trigger: "blur",
    },
  ],
  userName: [
    {
      required: true,
      message: "请输入姓名",
      trigger: "blur",
    },
  ],
  serviceIds: [
    {
      required: true,
      message: "请选择房间",
      trigger:["blur","change"],
    },
  ],
  mobile: [
    {
      required: true,
      message: "请输入联系方式",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "请填写正确的手机号（长度为11位）",
      trigger: "blur",
    },
  ],
  roleIds: [
    {
      required: true,
      message: "请选择角色",
      trigger: "blur",
    },
  ],
  roleName: [
    {
      required: true,
      message: "请填写角色",
      trigger: "blur",
    },
  ],
  roleCode: [
    {
      required: true,
      message: "请填写角色标识",
      trigger: "blur",
    },
  ],
  sex: [
    {
      required: true,
      message: "请选择性别",
      trigger: "blur",
    },
  ],
  isAdmin: [
    {
      required: true,
      message: "请选择类型",
      trigger: "blur",
    },
  ],
  merchantName: [
    {
      required: true,
      message: "请填写商户名称",
      trigger: "blur",
    },
  ],
  telPhone: [
    {
      required: true,
      message: "请输入商户手机",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "请填写正确的商户手机号（长度为11位）",
      trigger: "blur",
    },
  ],
  adminUserName: [
    {
      required: true,
      message: "请填写商户超管姓名",
      trigger: "blur",
    },
  ],
  legalPerson: [
    {
      required: true,
      message: "请填写商户法人",
      trigger: "blur",
    },
  ],
  districtId: [
    {
      required: true,
      message: "请选择地域",
      trigger: "blur",
    },
  ],
  businessLicenseFileId: [
    {
      required: true,
      message: "请上传营业执照",
      trigger: "blur",
    },
  ],
  shopName: [
    {
      required: true,
      message: "请填写店铺名称",
      trigger: "blur",
    },
  ],
  mchNo: [
    {
      required: true,
      message: "请填写特约商户账号",
      trigger: "blur",
    },
  ],
  brandMerchantId: [
    {
      required: true,
      message: "请选择商户",
      trigger: "blur",
    },
  ],
  shopType: [
    {
      required: true,
      message: "请选择商户类型",
      trigger: "blur",
    },
  ],
  serviceName: [
    {
      required: true,
      message: "请填写服务内容名称",
      trigger: "blur",
    },
  ],
  shopId: [
    {
      required: true,
      message: "请选择店铺",
      trigger: "blur",
    },
  ],
  serviceId: [
    {
      required: true,
      message: "请选择服务内容",
      trigger: "blur",
    },
  ],
  roomName: [
    {
      required: true,
      message: "请填写位置名称",
      trigger: "blur",
    },
  ],
  // 公众号消息模板
  templateName: [
    {
      required: true,
      message: "请填写模板名称",
      trigger: "blur",
    },
  ],
  wechatTemplateId: [
    {
      required: true,
      message: "请填写模板ID",
      trigger: "blur",
    },
  ],
  templateType: [
    {
      required: true,
      message: "请选择模板类型",
      trigger: "blur",
    },
  ],
  pagePath: [
    {
      required: true,
      message: "请填写跳转路径",
      trigger: "blur",
    },
  ],
  appletType: [
    {
      required: true,
      message: "请选择跳转小程序",
      trigger: "blur",
    },
  ],
  // 设备管理
  roomId: [
    {
      required: true,
      message: "请选择位置",
      trigger: "blur",
    },
  ],
  deviceType: [
    {
      required: true,
      message: "请选择设备类型",
      trigger: "blur",
    },
  ],
  deviceName: [
    {
      required: true,
      message: "请填写设备名称",
      trigger: "blur",
    },
  ],
  deviceModel: [
    {
      required: true,
      message: "请填写设备型号",
      trigger: "blur",
    },
  ],
  deviceNumber: [
    {
      required: true,
      message: "请填写外部地址",
      trigger: "blur",
    },
  ],
  deviceServiceType: [
    {
      required: true,
      message: "请选择设备服务商",
      trigger: "blur",
    },
  ],
  gatewayId: [
    {
      required: true,
      message: "请选择关联网关",
      trigger: "blur",
    },
  ],
  deviceIeNumber: [
    {
      required: true,
      message: "请填写设备IE地址",
      trigger: "blur",
    },
  ],
  // 设备型号管理
  goodsShelvesName: [
    {
      required: true,
      message: "请填写货格名称",
      trigger: "blur",
    },
  ],
  goodsShelvesNumber: [
    {
      required: true,
      message: "请填写控制编号",
      trigger: "blur",
    },
  ],
  goodsShelvesCapacity: [
    {
      required: true,
      message: "请填写货格容量",
      trigger: "blur",
    },
  ],
  trusteeshipPhone: [
    {
      required: true,
      message: "请填写托管电话",
      trigger: "blur",
    },
  ],
  money: [
    {
      required: true,
      message: "请输入提现金额",
      trigger: "blur",
    },
  ],
  brandMerchantName: [
    {
      required: true,
      message: "请输入商户名称",
      trigger: "blur",
    },
  ],

  merchantNo: [
    {
      required: true,
      message: "请输入商户号",
      trigger: "blur",
    },
  ],

  brandUserName: [
    {
      required: true,
      message: "请输入商户超管",
      trigger: "blur",
    },
  ],

  userMobile: [
    {
      required: true,
      message: "请输入超管手机号",
      trigger: "blur",
    },
  ],
  packageName: [
    {
      required: true,
      message: "请输入套餐名称",
      trigger: "blur",
    },
  ],
  rechargeMoney: [
    {
      required: true,
      message: "请输入充值金额",
      trigger: "blur",
    },
  ],
  giftMoney: [
    {
      required: true,
      message: "请输入赠送金额",
      trigger: "blur",
    },
  ],
  noticeName: [
    {
      required: true,
      message: "请输入公告名称",
      trigger: "blur",
    },
  ],
  noticeDetail: [
    {
      required: true,
      message: "请输入公告内容",
      trigger: "blur",
    },
  ],
  // 新增卡券
  cardName: [
    {
      required: true,
      message: "请填写卡券名称",
      trigger: "blur",
    },
  ],
  deductionPrice: [
    {
      required: true,
      message: "请填抵扣金额",
      trigger: "blur",
    },
  ],
  background: [
    {
      required: true,
      message: "请选择卡券样式",
      trigger: "blur",
    },
  ],
  cardType: [
    {
      required: true,
      message: "请选择卡券类型",
      trigger: "blur",
    },
  ],
  preMinHourNum: [
    {
      required: true,
      message: "请选择连续时长",
      trigger: "blur",
    },
  ],
  cardTypeNum: [
    {
      required: true,
      message: "请填写可用次数",
      trigger: "blur",
    },
  ],
  preferentialPrice: [
    {
      required: true,
      message: "请填写卡券价格",
      trigger: "blur",
    },
  ],
  originalPrice: [
    {
      required: true,
      message: "请填写划线价格",
      trigger: "blur",
    },
  ],
  enableStock: [
    {
      required: true,
      message: "请选择卡券库存",
      trigger: "blur",
    },
  ],
  effType: [
    {
      required: true,
      message: "请选择有效期限",
      trigger: "blur",
    },
  ],
  afterDayNum: [
    {
      required: true,
      message: "请填写有效天数",
      trigger: "blur",
    },
  ],
  endTime: [
    {
      required: true,
      message: "请填写有效日期",
      trigger: "blur",
    },
  ],
  enablePreLimit: [
    {
      required: true,
      message: "请选择预约限制",
      trigger: "blur",
    },
  ],
  enableRenewalLimit: [
    {
      required: true,
      message: "请选择续单限制",
      trigger: "blur",
    },
  ],
  // 平台商品管理
  goodsName: [
    {
      required: true,
      message: "请填写商品名称",
      trigger: "blur",
    },
  ],
  retailPrice: [
    {
      required: true,
      message: "请填写建议售价",
      trigger: "blur",
    },
  ],
  goodsDetail: [
    {
      required: true,
      message: "请填写商品详情",
      trigger: "blur",
    },
  ],
  instructions: [
    {
      required: true,
      message: "请填写使用说明",
      trigger: "blur",
    },
  ],
  imageId: [
    {
      required: true,
      message: "请上传商品缩略图",
      trigger: "blur",
    },
  ],
  imageIds: [
    {
      required: true,
      message: "请上传商品主图",
      trigger: "blur",
    },
  ],
  inventoryType: [
    {
      required: true,
      message: "请选择进销损类型",
      trigger: "blur",
    },
  ],
  inventory: [
    {
      required: true,
      message: "请填写数量",
      trigger: "blur",
    },
  ],
  // 积分配置
  obtainDate: [
    {
      required: true,
      message: "请选择日期段",
      trigger: "blur",
    },
  ],
  expDate: [
    {
      required: true,
      message: "请选择过期日期",
      trigger: "blur",
    },
  ],
  // 积分配置
  checkIn: [
    { pattern: /^\d+(;\d+){6};?$/, message: '请参照下方提示输入正确格式', trigger: ['blur', 'change']}
  ],
  // 平台商品分类
  goodsTypeName: [
    {
      required: true,
      message: "请填写分类名称",
      trigger: "blur",
    },
  ],
  // 积分兑卡券
  cardId: [
    {
      required: true,
      message: "请选择卡券",
      trigger: "blur",
    },
  ],
  requiredPoints: [
    {
      required: true,
      message: "请输入积分数",
      trigger: "blur",
    },
    {
      max: 10,
      message: "数字不得超过10位",
      trigger: ["change",'blur'],
    }
  ],
  sortNum: [
    {
      required: true,
      message: "请输入排序值",
      trigger: "blur",
    },
  ],
  //  积分兑商品
  goodsId: [
    {
      required: true,
      message: "请选择平台商品",
      trigger: "blur",
    },
  ],
  goodsTypeId: [
    {
      required: true,
      message: "请选择商品类别",
      trigger: "blur",
    },
  ],
  goodsPrice: [
    {
      required: true,
      message: "请输入原价",
      trigger: "blur",
    },
  ],
  requiredPoints: [
    {
      required: true,
      message: "请输入积分数",
      trigger: "blur",
    },
  ],
  goodsStock: [
    {
      required: true,
      message: "请输入兑换库存数",
      trigger: "blur",
    },
  ],
  shopIdList: [
    {
      required: true,
      message: "请选择门店",
      trigger: "blur",
    },
  ],
  sortNum: [
    {
      required: true,
      message: "请输入排序值",
      trigger: "blur",
    },
  ],
  // 平台充值活动
  activityName: [
    {
      required: true,
      message: "请输入活动名称",
      trigger: "blur",
    }
  ],
  rechargeAmount: [
    {
      required: true,
      message: "请输入充值金额",
      trigger: "blur",
    }
  ],
  canRechargeTimes: [
    {
      required: true,
      message: "请输入可充值次数",
      trigger: "blur",
    }
  ],
  signageText: [
    {
      required: true,
      message: "请输入角标文案",
      trigger: "blur",
    }
  ],
  activityNotice: [
    {
      required: true,
      message: "请输入活动须知",
      trigger: "blur",
    }
  ],
  activityShowConfigDTOList:[
    {
      required: true,
      message: "请添加展示配置",
      trigger: "blur",
    }
  ],
  welfareIcon:[
    {
      required: true,
      message: "请上传福利图标",
      trigger: "blur",
    }
  ],
  welfareDescription:[
    {
      required: true,
      message: "请输入福利说明",
      trigger: "blur",
    }
  ],
  shopIdSet:[
    {
      required: true,
      message: "请选择适用门店",
      trigger: "blur",
    }
  ],
   // 弹窗通知
   popupTitle: [
    {
      required: true,
      message: "请输入弹窗标题",
      trigger: "blur",
    },
  ],
  popupContent: [
    {
      required: true,
      message: "请输入弹窗内容",
      trigger: ['blur','change'],
    },
  ],
  contentType: [
    {
      required: true,
      message: "请选择跳转类型",
      trigger: "blur",
    },
  ],
  topNo:[
    {
      required: true,
      message: "请输入排序",
      trigger: "blur",
    }
  ],
  dateToDate: [
    {
      required: true,
      message: "请选择开始和结束日期",
      trigger: ["blur"],
    },
  ],


  // 服务类型模板
  templateName: [
    {
      required: true,
      message: "请输入模板名称",
      trigger: "blur",
    },
  ],
  pricingType: [
    {
      required: true,
      message: "请选择单价单位",
      trigger: "blur",
    },
  ],
  orderCancelNum: [
    {
      required: true,
      message: "请输入取消时间",
      trigger: "blur",
    },
  ],
  cleanNum: [
    {
      required: true,
      message: "请输入保洁时间",
      trigger: "blur",
    },
  ],
  depositNum: [
    {
      required: true,
      message: "请输入月数或金额",
      trigger: "blur",
    },
  ],
  storagePic: [
    {
      required: true,
      message: "请上传照片",
      trigger: "blur",
    },
  ],
  volumeRange: [
    {
      required: true,
      message: "请输入体积范围",
      trigger: "blur",
    },
  ],
  storageInfo: [
    {
      required: true,
      message: "请输入仓型介绍",
      trigger: "blur",
    },
  ],
  // 工位区域管理
  planName: [
    {
      required: true,
      message: "请输入区域名称",
      trigger: "blur",
    },
  ],
  serviceId: [
    {
      required: true,
      message: "请选择归属服务内容",
      trigger: ['blur', 'change'],
    },
  ],
  fileId: [
    {
      required: true,
      message: "请上传3D俯视图",
      trigger: ['blur', 'change'],
    },
  ]
 
};

